import React from 'react';
import PropTypes from 'prop-types';
import ListDivider from 'atomic-design/atoms/list-divider';
import { ChevronArrow } from 'ui/svg';
import { LocationContainer, Location, Title, Container, IconContainer } from './styles';

const JobPostText = ({ title, location }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <LocationContainer>
        <Location>{location}</Location>
      </LocationContainer>
      <ListDivider />
      <IconContainer>
        <ChevronArrow color="#CE49CF" />
      </IconContainer>
    </Container>
  );
};

JobPostText.propTypes = {
  title: PropTypes.string.isRequired,
};

export default JobPostText;

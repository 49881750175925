import styled from 'styled-components';

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: ${({ theme }) => theme.spacing.long};
  gap: ${({ theme }) => `${theme.newTheme.spacing(7)}`};
  width: 25%;

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    width: 50%;
    padding: ${({ theme }) => theme.spacing.medium};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
    padding: ${({ theme }) => theme.spacing.largeXL} 0;
  }
`;

export const Icon = styled.img`
  width: 5em;
  height: 5em;
`;

export const Title = styled.h5`
  margin: 0;
  font-family: ${({ theme }) => `${theme.newTheme.font.family}`};
  font-size: ${({ theme }) => `${theme.newTheme.font.title5.size}`};
  font-weight: ${({ theme }) => `${theme.newTheme.font.weight.semiBold}`};
  color: ${({ theme }) => `${theme.newTheme.color.primary.main}`};
`;

export const Text = styled.p`
  margin: 0;
  font-family: ${({ theme }) => `${theme.newTheme.font.family}`};
  font-weight: ${({ theme }) => `${theme.newTheme.font.weight.regular}`};
  font-size: ${({ theme }) => `${theme.newTheme.font.text2.size}`};
  color: ${({ theme }) => `${theme.newTheme.color.grey.main}`};
`;

import styled from 'styled-components';
import Link from 'atomic-design/atoms/link';

export const PageSection = styled.section`
  width: 100%;
  margin: ${({ theme }) => theme.newTheme.spacing(16)} 0;
`;

export const PageHeaderContainer = styled.section`
  padding-bottom: ${({ theme }) => theme.newTheme.spacing(12)};
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    padding-bottom: ${({ theme }) => theme.newTheme.spacing(6)};
    b {
      display: block;
    }
  }
`;

export const LinkStyled = styled(Link)`
  border-bottom: 2px solid ${({ theme }) => theme.newTheme.color.primary.main};
`;

export const NaniverseList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`;

export const NaniverseTitle = styled.h4`
  padding-bottom: ${({ theme }) => `${theme.newTheme.spacing(2)}`};

  font-size: ${({ theme }) => `${theme.newTheme.font.title3.size}`};
  line-height: ${({ theme }) => `${theme.newTheme.font.title3.size}`};
  font-weight: ${({ theme }) => `${theme.newTheme.font.weight.semiBold}`};

  span {
    font-size: ${({ theme }) => `${theme.newTheme.font.title3.size}`};
    line-height: ${({ theme }) => `${theme.newTheme.font.title3.size}`};
    font-weight: ${({ theme }) => `${theme.newTheme.font.weight.semiBold}`};
    background: ${({ theme }) => `${theme.gradients.heading}`};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      font-size: ${({ theme }) => `${theme.newTheme.font.title4.size}`};
      line-height: ${({ theme }) => `${theme.newTheme.font.title4.size}`};
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    font-size: ${({ theme }) => `${theme.newTheme.font.title4.size}`};
    line-height: ${({ theme }) => `${theme.newTheme.font.title4.size}`};
  }
`;

export const CollaborativeContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
`;

export const CollaborativeText = styled.h4`
  font-size: ${({ theme }) => theme.newTheme.font.title2.size};
  line-height: ${({ theme }) => theme.newTheme.font.title2.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.bold};
  padding: ${({ theme }) => `${theme.newTheme.spacing(6)} 0`};
  margin: 0;

  span {
    display: block;
    font-weight: ${({ theme }) => theme.newTheme.font.weight.bold};

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
      font-size: ${({ theme }) => theme.newTheme.font.title3.size};
      line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
      display: block;
    }

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      display: inline;
    }
  }

  span:nth-child(2) {
    background: ${({ theme }) => theme.newTheme.color.gradient.text};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.newTheme.font.title3.size};
    line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
    padding: 0;
  }
`;

export const JobPostTitle = styled.h4`
  color: ${({ theme }) => theme.newTheme.color.black};
  font-size: ${({ theme }) => theme.fonts.sizes.headings.heading4};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.bold};
`;

import React from 'react';

import MetaTags from 'core/meta-tags/index';
import Layout from 'core/layout';
import PageHeader from 'atomic-design/atoms/page-header';
import CallToAction from 'atomic-design/molecules/call-to-action';
import ManifestoSection from 'atomic-design/organisms/manifesto-section';
import PageSection from 'atomic-design/atoms/page-section';
import theme from 'styles/theme';

import {
  PageHeaderContainer,
  NaniverseList,
  NaniverseTitle,
  CollaborativeContent,
  CollaborativeText,
  JobPostTitle,
} from 'styles/pages/careers-styles';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { renderLineBreak } from 'common/string-helpers';

import Image from 'core/image';
import JobPostEntries from 'atomic-design/organisms/job-post-entries';
import NaniverseItem from 'atomic-design/molecules/naniverse-item';
import useIsMobile from 'hooks/useIsMobile';

export const carrersListQuery = graphql`
  query carrersListQuery {
    contentfulGeneralSettings {
      childContentfulGeneralSettingsCareerPageJsonNode {
        intro
        email
        items {
          icon
          title
          text
        }
      }
    }

    allContentfulPage(filter: { url: { eq: "careers" } }) {
      edges {
        node {
          headerTitle {
            json
          }
          headerSubtitle
          headerDescription {
            headerDescription
          }
          metatagImage {
            file {
              url
            }
          }
          metatagTitle
          metatagDescription
        }
      }
    }

    allContentfulCallToAction(filter: { text: { eq: "Apply here! We'd love to meet you" } }) {
      edges {
        node {
          text
          href {
            href
          }
          target
        }
      }
    }
  }
`;

const Careers = ({ data, location, pageContext }) => {
  const isMobile = useIsMobile('mobile');

  const { jobs } = pageContext;
  const careerData =
    data?.contentfulGeneralSettings.childContentfulGeneralSettingsCareerPageJsonNode;
  const pageHeaderData = data?.allContentfulPage.edges[0].node;
  const metatagImage = data?.allContentfulPage.edges[0].node.metatagImage.file.url;
  const { metatagDescription } = data?.allContentfulPage.edges[0].node;
  const { metatagTitle } = data?.allContentfulPage.edges[0].node;
  const defaultApplyCTA = data?.allContentfulCallToAction.edges[0].node;

  return (
    <Layout path={location.pathname} bodyType="xWide">
      <MetaTags
        title={metatagTitle}
        description={metatagDescription}
        charSet="utf-8"
        image={metatagImage}
        viewport="width=device-width, initial-scale=1.0"
        type="website"
      />

      <PageSection>
        <PageHeaderContainer>
          <PageHeader
            title={pageHeaderData.headerSubtitle}
            subTitle={documentToReactComponents(pageHeaderData.headerTitle.json)}
            description={renderLineBreak(pageHeaderData.headerDescription.headerDescription)}
          />
        </PageHeaderContainer>
      </PageSection>

      <Image
        alt="We Are Hiring. Join Us!"
        partialPathName={!isMobile ? 'careers-hero-desk' : 'careers-hero-mob'}
        width="100%"
        fluid
      />

      <PageSection margin={`${theme.newTheme.spacing(16)} 0`}>
        <NaniverseTitle>
          Our
          <span> NaNiverse </span>
          is built on
        </NaniverseTitle>
        <NaniverseList>
          {careerData.items.map(item => (
            <NaniverseItem key={item.title} icon={item.icon} title={item.title} text={item.text} />
          ))}
        </NaniverseList>
      </PageSection>

      <PageSection
        margin={`${theme.newTheme.spacing(16)} 0`}
        background={`${theme.newTheme.color.primary.light}20`}
        padding={`${theme.newTheme.spacing(16)} 0`}
      >
        <ManifestoSection
          title={['The NaNLABS ', <span>Manifesto</span>]}
          description={[
            'Heard of the software craftsmanship manifesto?',
            <span>It's a cornerstone of the NaNLABS way.</span>,
            <span>But we've also got our own. This is the code we live by:</span>,
          ]}
        />
      </PageSection>

      <PageSection margin={`${theme.newTheme.spacing(16)} 0`}>
        <CollaborativeContent>
          <CollaborativeText>
            Collaborative, curious, <span>and a little bit crazy?</span>
            <span> You'll fit right in.</span>
          </CollaborativeText>
        </CollaborativeContent>
      </PageSection>

      <PageSection
        margin={`${theme.newTheme.spacing(16)} 0 ${theme.newTheme.spacing(24)}`}
        containerSize="smallJobs"
      >
        <JobPostTitle>Check out our open roles</JobPostTitle>
        {jobs.length > 0 && <JobPostEntries jobPostEntries={jobs} fromApi />}
        <CallToAction
          to={defaultApplyCTA.href.href}
          sectionTitle="You didn't find your ideal role?"
          buttonTitle={defaultApplyCTA.text}
        />
      </PageSection>
    </Layout>
  );
};

Careers.propTypes = {
  jobs: PropTypes.array,
};

export default Careers;

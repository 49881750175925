import React from 'react';
import PropTypes from 'prop-types';
import JobPost from 'atomic-design/organisms/job-post';
import { convertToSlug } from 'common/string-helpers';
import { Container } from './styles';

const JobPostEntries = ({ jobPostEntries, fromApi = false }) => {
  return (
    <Container>
      {jobPostEntries.map(edge => {
        const slug = convertToSlug(edge.node.title);
        return (
          <JobPost
            key={slug}
            title={edge.node.title}
            department={edge.node.departments[0].name}
            slug={fromApi ? slug : edge.node.slug}
            to={`/careers/${fromApi ? slug : edge.node.slug}`}
            location={fromApi ? edge.node.location.name : edge.node.location}
          />
        );
      })}
    </Container>
  );
};

JobPostEntries.propTypes = {
  // jobPostEntries: PropTypes.node.isRequired,
  jobPostEntries: PropTypes.isRequired,
  fromApi: Boolean,
};

JobPostEntries.defaultProps = {
  fromApi: false,
};

export default JobPostEntries;

import React from 'react';
import PropTypes from 'prop-types';

import Link from 'atomic-design/atoms/link';
import JobPostText from './text';
import { Container } from './styles';

const JobPost = ({ title, department, to, slug, location }) => {
  return (
    <Link to={to} key={slug} rel={slug}>
      <Container>
        <JobPostText title={title} department={department} location={location} />
      </Container>
    </Link>
  );
};

JobPost.propTypes = {
  title: PropTypes.string.isRequired,
  department: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
};

export default JobPost;

import styled from 'styled-components';

export const LocationContainer = styled.div`
  display: flex;
  width: 100%;
  padding: ${({ theme }) =>
    `${theme.newTheme.spacing(2.5)} 0 ${theme.newTheme.spacing(3.5)} ${theme.newTheme.spacing(1)}`};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    padding: ${({ theme }) => `${theme.newTheme.spacing(1.5)} 0 ${theme.newTheme.spacing(2.5)} 0`};
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.smallMobile}) {
    padding: ${({ theme }) => `${theme.newTheme.spacing(1.5)} 0 ${theme.newTheme.spacing(2.5)} 0`};
  }
`;

export const Location = styled.h4`
  color: ${({ theme }) => theme.newTheme.color.grey.main};
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  font-style: normal;
  font-weight: ${({ theme }) => theme.newTheme.font.weight.light};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  padding: 0;
  margin: 0;
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.newTheme.color.primary.main};
  font-size: ${({ theme }) => theme.newTheme.font.title5.size};
  font-style: normal;
  font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
  line-height: ${({ theme }) => theme.newTheme.font.title5.lineHeight};
  margin 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  flex-basis: 0;
  flex: 1;
  position: relative;

  p {
    color: ${({ theme }) => theme.newTheme.color.white};
    font-family: ${({ theme }) => theme.newTheme.font.family};
    font-size: ${({ theme }) => theme.newTheme.font.text3.size};
    padding: 2px 0;
    margin: 0;
  }

  hr {
    background: ${({ theme }) => theme.newTheme.color.gradient.background.primary.main};
    width: 100%;
    height: 1px;
  }
`;

export const IconContainer = styled.div`
  transform: translate(-50%, -50%) rotate(-90deg);
  right: -10px;
  top: 50%;
  position: absolute;

  svg {
    transition: ${({ theme }) => theme.newTheme.transition.main};
    transform: scale(2.5);
    width: 100%;

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.smallMobile}) {
      transform: scale(2);
    }
  }
`;

import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  display: flex;
  margin: ${({ theme }) => `0 0 ${theme.newTheme.spacing(17)} 0`};
  overflow: hidden;
  width: 100%;

  &:hover {
    svg {
      transform: translateY(10px) scale(2.5);
      transition: ${({ theme }) => theme.transition};
      filter: saturate(8) brightness(0.6);
    }

    h3 {
      text-decoration: underline;
    }

    hr {
      background: ${({ theme }) => theme.newTheme.color.gradient.background.primary.hover};
      transition: all 0.3s ease-in-out;
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    margin: ${({ theme }) => `0 0 ${theme.newTheme.spacing(13)} 0`};
    flex-direction: column;

    h3 {
      font-size: ${({ theme }) => theme.newTheme.font.title6.size};
      line-height: ${({ theme }) => theme.newTheme.font.title6.lineHeight};
    }

    h4 {
      font-size: ${({ theme }) => theme.newTheme.font.text2.size};
      line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
    }

    p {
      font-size: ${({ theme }) => theme.newTheme.font.text4.size};
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.smallMobile}) {
    margin: ${({ theme }) => `0 0 ${theme.newTheme.spacing(10)} 0`};
    flex-direction: column;

    h3 {
      font-size: ${({ theme }) => theme.newTheme.font.text1.size};
      line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
      max-width: 270px;
    }

    h4 {
      font-size: ${({ theme }) => theme.newTheme.font.text3.size};
      line-height: ${({ theme }) => theme.newTheme.font.text3.lineHeight};
    }

    p {
      font-size: ${({ theme }) => theme.newTheme.font.text4.size};
    }

    &:hover svg {
      transform: translateY(10px) scale(2);
      transition: ${({ theme }) => theme.transition};
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';

import OpportunitiesIcon from 'static/images/icons/opportunities-icon.svg';
import InnovationIcon from 'static/images/icons/innovation-icon.svg';
import TrustIcon from 'static/images/icons/trust-icon.svg';
import TeamWorkIcon from 'static/images/icons/team-work-icon.svg';
import { ItemContainer, Icon, Title, Text } from './styles';

const NaniverseItem = ({ icon, title, text }) => {
  const careerIcons = {
    'opportunities-icon': OpportunitiesIcon,
    'innovation-icon': InnovationIcon,
    'creativity-icon': TrustIcon,
    'team-work-icon': TeamWorkIcon,
  };
  return (
    <ItemContainer>
      <Icon src={careerIcons[icon]} alt={title} />
      <Title>{title}</Title>
      <Text>{text}</Text>
    </ItemContainer>
  );
};

NaniverseItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired,
};

export default NaniverseItem;
